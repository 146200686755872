.list-icons {
	display: flex;

	.list-item {
		margin-right: 0.5em;

		&:last-child {
			margin-right: 0;
		}
	}

	&.icons-checks {
		flex-direction: column;
		margin-top: 0.825em;
		margin: 1em auto 0 5em;
		font-size: 0.875em;

		.list-item {
			display: flex;
			margin-bottom: 0.5em;

			&:last-child {
				margin-right: 0;
			}
		}

		.list-item:before {
			margin-right: 0.5em;
			margin-top: 0.25em;
			content: "";
			display: block;
			background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5 14H1.5C1.08333 14 0.729167 13.8542 0.4375 13.5625C0.145833 13.2708 0 12.9167 0 12.5V1.5C0 1.08333 0.145833 0.729167 0.4375 0.4375C0.729167 0.145833 1.08333 0 1.5 0H12.5C12.9167 0 13.2708 0.145833 13.5625 0.4375C13.8542 0.729167 14 1.08333 14 1.5V12.5C14 12.9167 13.8542 13.2708 13.5625 13.5625C13.2708 13.8542 12.9167 14 12.5 14ZM6.09375 10.9375L11.8438 5.1875C12.0938 4.95833 12.0938 4.71875 11.8438 4.46875L11.1562 3.78125C10.9062 3.53125 10.6667 3.53125 10.4375 3.78125L5.75 8.46875L3.5625 6.28125C3.33333 6.03125 3.09375 6.03125 2.84375 6.28125L2.15625 6.96875C1.90625 7.21875 1.90625 7.45833 2.15625 7.6875L5.40625 10.9375C5.63542 11.1667 5.86458 11.1667 6.09375 10.9375Z' fill='%230AB38B'/%3E%3C/svg%3E");
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			width: 1em;
			height: 1em;
		}

		@media (max-width: $breakpoint-medium) {
			margin-left: 0;
		}
	}
}

.list-posts {
	counter-reset: list-posts;

	a {
		color: black;
		font-size: 1em;
		font-weight: bold;
		padding: 1.5em 1em 1.5em 5em;
		display: flex;
		align-items: center;
		line-height: 1.2;
		min-height: 5.5em;

		&:hover {
			background: #fafafa;
			text-decoration: underline;
		}
	}

	.list-item {
		border-bottom: 1px dashed #ddd;
		counter-increment: list-posts;
		position: relative;

		&:last-child {
			border-bottom: none;
		}

		&:before {
			position: absolute;
			content: counter(list-posts) ".";
			top: 0.6em;
			left: 1em;
			font-size: 2em;
			font-weight: 500;
		}
	}

	@media (max-width: 480px) {
		a {
			font-weight: 600;
		}
	}
}
