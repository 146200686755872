.site-profile-main {
	.button.button-circle {
		background: rgba(white, .1);
		color: white;
		display: inline-flex;
		align-items: center;
		margin-left: 0.5em;
		justify-content: center;

		.fa {
			height: 1em;
			width: 1em;
			background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='user-circle' class='svg-inline--fa fa-user-circle fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 496 512'%3E%3Cpath fill='%23ffffff' d='M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z'%3E%3C/path%3E%3C/svg%3E");
		}
	}

	.user-logins {
		display: none;
		position: absolute;
		width: 100%;

		&.active {
			display: block;
		}
	}
}

.user-profile {
	display: none;

	&.active {
		display: block;
	}

	.user-avatar {
		border-radius: 50%;
	}
}

.email-form {
	display: none;
	border: 1px solid #ddd;
	padding: 3.25em;
	border-radius: 0.5em;
	margin: 0;
	// overflow: hidden;

	background-image: url(../images/bg-rays.svg), radial-gradient(#0064cc, #006ee0 70%);
	background-position: center center;
	box-shadow: 0 10px 15px -5px rgba(black, 0.1);
	color: white;
	text-align: center;

	position: fixed;
	width: calc(100vw - 3em);
	height: calc(100vh - 3em);
	top: 50%;
	left: 50%;
	z-index: 210;
	transform: translate(-50%, -50%);

	max-width: 40em;
	max-height: 40em;

	h3 {
		margin-bottom: 1em;
	}

	.see-results-button {
		appearance: none;
		border: none;
		background: transparent;
		color: white;
		font-size: 0.875em;
		display: block;
		text-align: center;
		margin-top: 1em;
		width: 100%;
	}

	.email-form-loading-magician {
		min-height: 18em;
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-right: 3em;
	}

	.magician-bubble {
		background: white;
		padding: 1em;
		border-radius: 1em 1em 0 1em;
		color: black;

		p {
			margin: 0;
		}
	}

	@media (max-width: 430px) {
		padding: 1.75em;

		.email-form-loading-magician {
			padding-right: 5em;
		}
	}
}