.button {
	display: inline-block;

	// Over the top on purpose to create pull effect.
	border-radius: 123em;
	border: none;

	padding: 0.5em 1em;
	font-weight: 700;

	&:focus {
		outline: none;
	}

	// Neutral background and foreground work in case you do a
	// dark mode and want to invert the black/white buttons.
	&.button-neutral-background {
		background-color: white;
		color: $color-primary;
	}

	&.button-neutral-foreground {
		background-color: black;
		color: white;
	}

	// because icons have width, we might want to ignore that and force a circle.
	&.button-circle {
		height: 2.74em;
		width: 2.74em;
		line-height: 2.74;
		text-align: center;
		padding: 0;
	}

	&.button-secondary {
		background-color: $color-secondary;
		color: white;
	}

	&.button-primary {
		background-color: $color-primary;
		color: white;
	}

	// This is a button with gradient outline.
	&.button-fancy {
		position: relative;
		background: white;
		color: black;
		font-weight: 500;
		padding-left: 2em;
		padding-right: 8em;
		font-size: 1.5em;
		display: inline-flex;
		align-items: center;
		box-shadow: 0 20px 50px -10px rgba(black, 0.2);

		svg {
			max-height: 1em;
			flex: 0 0 1em;
			margin-right: 1em;
		}

		span {
			flex: 0 0 100%;
		}

		&:before {
			content: "";
			z-index: -1;
			display: block;
			border-radius: 100em;
			top: -4px;
			left: -4px;
			height: calc(100% + 8px);
			width: calc(100% + 8px);
			background: linear-gradient(
				90deg,
				#e33d3d 0%,
				#ffc700 20%,
				#ffa05d 40%,
				#2bcaa4 60%,
				#0051a6 80%,
				#d848db 100%
			);
			position: absolute;
			transition: 0.3s;
		}

		&:hover:before {
			top: -8px;
			left: -8px;
			height: calc(100% + 16px);
			width: calc(100% + 16px);
			transition: 0.3s;
		}

		@media (max-width: 460px) {
			font-size: 1em;
		}
	}

	&.button-magician {
		&:after {
			content: "";
			display: block;
			height: 8.75em;
			width: 4.125em;
			background: url("../images/magician.svg") no-repeat;
			background-size: contain;
			position: absolute;
			right: 1em;
			top: -3.33em;
			transform: rotate(15deg);

			transition: 0.2s;
		}

		&:hover:after {
			transform: rotate(0);
			transition: 0.2s;
		}

		@media (max-width: 460px) {
			font-size: 1em;
		}
	}
}

.button-profile-delete {
	padding: 1em 1.75em;
	color: #e33d3d;
	background-color: #ffffff;
	border: 2px solid #ee2c2c;
	box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.1);
	border-radius: 123px;
	font-weight: 700;

	@media (max-width: $breakpoint-small) {
		margin-top: 1.5em;
	}
}

.button-unsubscribe {
	padding: 1em 1.75em;
	color: #000000;
	background-color: #ffffff;
	border: 2px solid #efefef;
	border-radius: 123px;
	font-weight: 700;

	@media (max-width: $breakpoint-small) {
		margin-top: 1.5em;
	}
}

// Seen in a popup with login options
.button-social-login {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 17.75em;
	width: 100%;
	border-radius: 5em;
	padding: 1.5em;
	margin-bottom: 1em;

	.login-button-icon {
		width: 1.75em;
		margin-right: 1em;
	}

	svg {
		display: block;
		margin: 0 auto;
	}

	&.social-login-snapchat {
		background: #fffc00;
		color: black;
	}

	&.social-login-facebook {
		background: #0162c7;
		color: white;
	}

	&.social-login-google {
		background: #ffffff;
		color: #000000;
		border: 1px solid #ddd;
		font-weight: 600;
	}
}

.login-button-icon {
	button {
		background-position: 29px center;
		background-size: 24px;
	}

	span {
		font-size: 16px;
		font-weight: 700;
	}
}
