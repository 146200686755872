.popup-card .privacy-policy {
	margin-top: 1.5em;
	font-size: 0.9em;
	color: #fff;
	max-width: 75%;
	margin: 0 auto;
	text-align: center;
	margin-top: 1em;

	a {
		border-bottom: 1px solid #fff;
		font-weight: 600;
		color: #fff;
		font-weight: 400;
	}
}
