.section-heading {
	margin-bottom: 2em;

	&-flex {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}

	.heading-cta {
		color: $color-primary;
		font-weight: bold;

		a {
			color: $color-primary;
		}
	}

	&.heading-archive {
		text-align: center;
		margin-bottom: 0;

		.heading-title {
			font-size: 5em;
		}

		span {
			opacity: 0.5;

			&:after {
				content: "";
				display: inline-block;
				margin: 0 0.1em;
				height: 0.71em;
				width: 2px;
				background: rgba(white, 0.5);
			}
		}

		@media (max-width: 1024px) {
			.heading-title {
				font-size: 3em;
			}
		}
	}

	@media (max-width: 480px) {
		&-flex {
			flex-direction: column;
			align-items: flex-start;
		}
	}
}

.box-tags {
	background: white;
	padding: 1em;

	ul {
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: center;
	}

	li {
		flex: 0 1 7em;
		text-align: center;

		&:last-child {
			margin-right: 0;
		}
	}

	a {
		color: black;
		font-weight: 500;
		transition: 0.2s transform;

		&:hover {
			transition: 0.2s transform;
			text-decoration: underline;
		}
	}
}

.search-form,
.header-search-form {
	.search-field {
		border: none;
		background: #f0f0f0;
		font-size: 1em;
		padding: 0.5em 1em;
		border-radius: 10em;
		box-shadow: inset 0 0 0 1px #ddd;

		&:focus {
			outline: none;
		}
	}

	.search-submit {
		@extend .button;
		@extend .button.button-secondary;

		&:focus {
			outline: none;
		}
	}
}

.posts-navigation {
	.nav-links {
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;
	}

	a {
		@extend .button;
		@extend .button.button-secondary;
	}
}

.other-sites {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	.other-site-box {
		margin: 1em;
		flex: 0 0 10em;
		text-align: center;

		h3 {
			font-weight: 500;
			min-height: 3.5em;
		}
	}

	@media (max-width: 1200px) and (min-width: 1000px) {
		.other-site-box {
			flex: 0 0 16em;
		}
	}

	@media (max-width: $breakpoint-small) {
		.other-site-box h3 {
			min-height: 0;
		}
	}
}

.profile-user-welcome {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top: 1.5em;

	.profile-user-photo {
		width: 6.25em;
		height: 6.25em;
		position: relative;

		img {
			width: 6.25em;
			height: 6.25em;
		}

		svg {
			width: 400px;
			height: 300px;
			position: absolute;
			top: -100px;
			left: -150px;
			pointer-events: none;
		}
	}

	img {
		display: block;
		box-shadow: 0 0 0 10px darken($color-primary, 5%);
		border-radius: 50%;
	}

	.profile-user-info {
		text-align: center;
		margin-top: 2em;
	}

	.user-name {
		font-size: 3.5em;
		font-weight: 900;
	}

	.user-score {
		font-size: 1.25em;
		font-weight: 500;
	}

	@media (max-width: $breakpoint-small) {
		.user-name {
			font-size: 2em;
		}
	}
}

.btn-take-quiz {
	padding: 1em;
	border-radius: 0.5em;
	background-color: #2d8de1;
	font-weight: 600;
	transition: 0.2s ease-in-out;
	display: inline-block;
	
	&:hover {
		background-color: rgba(#2d8de1, 0.5);
	}
	
	.page-template-user-profile & {
		margin-top: 1em;
	}
}

.quiz-history-box {
	display: flex;
	padding: 2em;
	border-radius: 1em;
	box-shadow: 0 0 0 1px rgba(black, 0.2);
	margin-bottom: 1.5em;
	background: white;

	.quiz-thumbnail-wrapper {
		margin-right: 1.5em;
		position: relative;
		overflow: hidden;
		border-radius: 0.5em;
		flex: 0 0 33%;

		.quiz-thumbnail {
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		picture {
			position: absolute;
			width: 100%;
			height: 100%;
			background: #fafafa;
		}

		.quiz-date-taken {
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);
			background-color: rgba(black, 0.8);
			color: white;
			font-weight: 700;
			width: 100%;
			font-size: 12px;
			padding: 0.25em;
			text-align: center;
		}
	}

	.quiz-footer {
		margin-top: 1em;
	}

	.quiz-retake {
		color: #444;
		background: #fff;
		margin-left: 0.5em;

		&:hover {
			background: #fafafa;
			color: $color-primary;
		}
	}

	.quiz-see-results {
		color: $color-primary;
		box-shadow: 0 10px 20px -10px rgba(black, 0.2);
		border: 1px solid #ddd;

		&:hover {
			background: $color-primary;
			border-color: $color-primary;
			color: white;
		}
	}

	@media (max-width: $breakpoint-small) {
		flex-direction: column;
		padding: 0.75em;
		margin: 1em;

		.quiz-thumbnail-wrapper {
			margin-right: 0;
			margin-bottom: 1em;
			padding-bottom: 50%;
		}
	}
}

.quiz-history-box.empty {
	height: 160px;
}

.load-more-area {
	min-height: 6.5em;
	font-size: 1.25em;
}

.results-container {
	background-size: 100%;
	background-image: url("data:image/svg+xml,%3Csvg width='821' height='1459' viewBox='0 0 821 1459' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='741' width='821' height='224' rx='16' fill='%23FAFAFA'/%3E%3Crect y='988' width='821' height='224' rx='16' fill='%23FAFAFA'/%3E%3Crect y='1235' width='821' height='224' rx='16' fill='%23FAFAFA'/%3E%3Crect y='494' width='821' height='224' rx='16' fill='%23FAFAFA'/%3E%3Crect y='247' width='821' height='224' rx='16' fill='%23FAFAFA'/%3E%3Crect width='821' height='224' rx='16' fill='%23FAFAFA'/%3E%3C/svg%3E%0A");

	.quiz-history-box {
		animation-name: showIn;
		animation-fill-mode: both;
		animation-duration: 1s;
	}

	&.has-loaded {
		background: transparent;
	}

	@for $i from 1 through 10 {
		.quiz-history-box:nth-child(3n + #{$i}) {
			animation-delay: #{$i * 100 + ms};
		}
	}
}

// Toggle button
/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 80px;
	height: 44px;

	@media (max-width: $breakpoint-small) {
		margin-top: 1em;
	}
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	top: 50%;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	transform: translate(4px, -50%);
}

input:checked + .slider {
	background-color: rgba(0, 110, 224, 0.9);
}

input:focus + .slider {
	box-shadow: 0 0 1px rgba(0, 110, 224, 0.9);
}

input:checked + .slider:before {
	transform: translate(46px, -50%);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

@keyframes showIn {
	0% {
		opacity: 0;
		// transform: translateY(100px);
	}
	100% {
		opacity: 1;
		// transform: translateY(0);
	}
}
