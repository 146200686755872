// Here is your main header stylings. You can modify all other, but in general
// try to keep the header stylings in here.
//
// Menu stylings file be found in components/mobile-menu

// Header will always be fixed on top.
// Spacer will be defined for the top background in css.
.site-header {
	@extend .section-fullwidth.text-white;

	top: 0;
	left: 0;
	width: 100%;
	z-index: 110;
	padding: 1em 0;
	background: transparent;
	position: fixed;

	.search-form {
		display: none;
	}

	.header-ctas {
		display: flex;
		position: relative;

		.site-page-share {
			margin-left: 0.5em;
			line-height: 1.7;
			box-shadow: 0px 4px 33px 0px rgba($color: #000000, $alpha: 0.2);
			display: flex;
			align-items: center;

			@media (max-width: 320px) {
				padding: 0.5em;
			}
		}

		.loader,
		.loader:after {
			border-radius: 50%;
			height: 2.74em;
			width: 2.74em;
		}
		.loader {
			margin-left: 0.5em;
			position: relative;
			text-indent: -9999em;
			border-top: 0.5em solid rgba(255, 255, 255, 0.2);
			border-right: 0.5em solid rgba(255, 255, 255, 0.2);
			border-bottom: 0.5em solid rgba(255, 255, 255, 0.2);
			border-left: 0.5em solid #ffffff;
			-webkit-transform: translateZ(0);
			-ms-transform: translateZ(0);
			transform: translateZ(0);
			-webkit-animation: load8 1.1s infinite linear;
			animation: load8 1.1s infinite linear;
		}
	}

	.site-social-links {
		// This is to make it equal to the right part in order to center the title.
		max-width: 8.125em;
		width: 100%;

		.list-item {
			margin-right: 1.5em;
		}

		i {
			width: 1.25em;
			height: 1.25em;
			display: inline-block;
			background-position: center center;
			background-repeat: no-repeat;
		}

		.icon-facebook-f {
			background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fab' data-icon='facebook-f' class='svg-inline--fa fa-facebook-f fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23ffffff' d='M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z'%3E%3C/path%3E%3C/svg%3E");
		}

		.icon-twitter {
			background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fab' data-icon='twitter' class='svg-inline--fa fa-twitter fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z'%3E%3C/path%3E%3C/svg%3E");
		}

		.icon-pinterest-p {
			background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fab' data-icon='pinterest-p' class='svg-inline--fa fa-pinterest-p fa-w-12' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cpath fill='%23ffffff' d='M204 6.5C101.4 6.5 0 74.9 0 185.6 0 256 39.6 296 63.6 296c9.9 0 15.6-27.6 15.6-35.4 0-9.3-23.7-29.1-23.7-67.8 0-80.4 61.2-137.4 140.4-137.4 68.1 0 118.5 38.7 118.5 109.8 0 53.1-21.3 152.7-90.3 152.7-24.9 0-46.2-18-46.2-43.8 0-37.8 26.4-74.4 26.4-113.4 0-66.2-93.9-54.2-93.9 25.8 0 16.8 2.1 35.4 9.6 50.7-13.8 59.4-42 147.9-42 209.1 0 18.9 2.7 37.5 4.5 56.4 3.4 3.8 1.7 3.4 6.9 1.5 50.4-69 48.6-82.5 71.4-172.8 12.3 23.4 44.1 36 69.3 36 106.2 0 153.9-103.5 153.9-196.8C384 71.3 298.2 6.5 204 6.5z'%3E%3C/path%3E%3C/svg%3E");
		}
	}

	.header-search-form {
		opacity: 0;
		position: absolute;
		pointer-events: none;
		right: calc(100% + 1em);
		top: 2px;
		max-width: 8em;

		.search-field {
			max-width: 100%;
			background: white;
			box-shadow: none;
		}

		&.visible {
			opacity: 1;
			pointer-events: all;
		}

		label {
			text-indent: -9999em;
			display: block;
		}
	}

	.mobile-offcanvas-menu-trigger {
		display: none;
	}

	.mobile-offcanvas-menu {
		display: none;
	}

	.site-search-button.button.button-circle,
	.site-page-share.button.button-circle {
		background: rgba(white, 0.1);
		color: white;
		display: inline-flex;
		align-items: center;
		justify-content: center;

		.fa {
			height: 1em;
			width: 1em;
			background-repeat: no-repeat;
			background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='search' class='svg-inline--fa fa-search fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z'%3E%3C/path%3E%3C/svg%3E");
		}
	}

	.site-page-share.button.button-circle .fa {
		background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='share-alt' class='svg-inline--fa fa-share-alt fa-w-14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='white' d='M352 320c-25.6 0-48.9 10-66.1 26.4l-98.3-61.5c5.9-18.8 5.9-39.1 0-57.8l98.3-61.5C303.1 182 326.4 192 352 192c53 0 96-43 96-96S405 0 352 0s-96 43-96 96c0 9.8 1.5 19.6 4.4 28.9l-98.3 61.5C144.9 170 121.6 160 96 160c-53 0-96 43-96 96s43 96 96 96c25.6 0 48.9-10 66.1-26.4l98.3 61.5c-2.9 9.4-4.4 19.1-4.4 28.9 0 53 43 96 96 96s96-43 96-96-43-96-96-96zm0-272c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM96 304c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm256 160c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z'%3E%3C/path%3E%3C/svg%3E");
	}

	.site-page-share {
		background: rgba(white, 0.1);
		color: white;
	}

	.site-profile-main,
	.site-profile-login {
		margin-left: 0.5em;
	}

	.site-profile-login {
		display: none;
	}

	.site-profile-main {
		display: none;
		position: relative;

		&.is-visible {
			display: flex;
			align-items: center;
			cursor: pointer;
			position: relative;
			justify-content: center;
		}

		&::before {
			display: block;
			content: "";
			position: absolute;
			width: 4.74em;
			height: 7.74em;
			top: 0;
			left: -1em;
			right: 0;
			bottom: 0;
		}

		.is-visible.profile-dropdown {
			pointer-events: all;
			opacity: 1;
			transition: 0.5s;
			// transform: translateY(0);
		}

		.profile-name {
			min-width: 3.5em;
			text-align: right;
			padding-right: 0.5em;
			color: black;
		}

		.profile-dropdown {
			pointer-events: none;
			opacity: 0;
			transition: 0.5s;
			z-index: 140;

			position: absolute;
			right: 0;
			top: calc(100% + 0.5em);
			min-width: 17.5em;
			background: white;
			border-radius: 1em;
			padding: 1em 1.75em;
			cursor: default;

			&-username-wrap {
				display: none;
			}

			&-magician {
				display: none;
			}

			.blue-bg {
				display: none;
			}

			&:before {
				position: absolute;
				top: -0.5em;
				height: 0.5em;
				width: 100%;
				content: "";
				display: block;
			}

			a {
				color: black;
				font-weight: normal;
				font-size: 0.875em;
				padding: 0.5em 0;
				display: flex;
				align-items: center;
			}

			.completed-quizzes-link {
				color: #00b946;
			}

			.dropdown-icon {
				width: 2em;
				display: flex;

				img {
					height: 100%;
					object-fit: contain;
				}
			}

			li {
				border-bottom: 1px solid #ddd;

				&:last-child {
					border-bottom: 0;
				}
			}
		}

		.profile-image img {
			width: 1.5em;
			height: 1.5em;
			object-fit: cover;
			display: block;
			border-radius: 50%;
		}

		.profile-menu-logout {
			color: red;

			a {
				color: red;
			}
		}
	}

	&.headroom--not-top {
		background: rgba($color-primary, 0.9);
		// backdrop-filter: blur(5px);
	}

	.page & {
		background: rgba($color-primary, 0.9);
	}

	.branding {
		display: block;
	}

	.mobile &,
	.tablet & {
		background: rgba($color-primary, 0.9);
		backdrop-filter: blur(5px);
		padding: 0.5em 0;
		height: 70px;
		transition: 0.3s transform;
		will-change: transform;

		.site-social-links {
			display: none;
		}

		.branding {
			max-height: 2em;
			width: auto;
		}

		&.headroom--unpinned {
			transform: translateY(-60px);
			transition: 0.3s;
		}
	}

	.mobile & {
		.header-ctas {
			align-items: center;
		}

		.mobile-offcanvas-menu-trigger {
			display: block;
			box-shadow: 0px 4px 33px 0px rgba($color: #000000, $alpha: 0.2);
			padding: 10px;
			border-radius: 50%;

			&::before {
				display: block;
				content: "";
				width: 14px;
				height: 16px;
				background: url("../images/bars-solid.svg") no-repeat center center;
				background-size: contain;
			}
		}

		.profile-dropdown-username {
			margin-right: 2em;
		}

		.mobile-offcanvas-menu {
			&_form-wrapper {
				position: relative;

				&::after {
					display: block;
					content: "";
					background: url("../images/seach.svg") no-repeat center center;
					background-size: contain;
					width: 16px;
					height: 16px;
					position: absolute;
					top: 50%;
					left: 11px;
					transform: translateY(-50%);
				}
			}

			&_search-form {
				width: 100%;
			}

			.search-field {
				width: 100%;
				background-color: #2184eb;
				box-shadow: 0px 4px 33px 0px rgba($color: #000000, $alpha: 0.2);
				color: #fdfdfd;
				border: none;
				outline: none;
				padding: 0.7em 1em 0.7em 2.7em;
				border-radius: 123px;
				font-size: 0.875em;

				&::placeholder {
					color: #fdfdfd;
				}
			}

			&_categories-list {
				margin-top: 1em;

				&-item {
					border-bottom: 1px solid #1880eb;
					color: #fdfdfd;
					padding: 10px 16px;
					font-size: 0.875em;
					display: flex;
					align-items: center;

					&:last-child {
						border-bottom: none;
					}

					&::before {
						display: block;
						content: "";
						background: url("../images/dropdown.svg") no-repeat center center;
						background-size: contain;
						width: 8px;
						height: 16px;
						margin-right: 1em;
					}
				}
			}

			&_footer {
				margin-bottom: 3.5em;
				margin-top: auto;
				border-top: 1px solid #1880eb;
				padding-top: 1.4em;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			&.is-visible {
				display: flex;
				position: fixed;
				background-color: #0069d7;
				min-height: 100vh;
				width: 100vw;
				top: 60px;
				left: 0;
				bottom: 0;
				right: 0;
				z-index: 140;
				flex-direction: column;
				padding: 1.4em 0.875em;

				.site-social-links {
					display: flex;
					justify-content: flex-end;
				}
			}
		}

		.site-profile-main {
			padding: 0;

			.profile-image {
				img {
					width: 2.5em;
					height: 2.5em;
				}
			}

			.profile-dropdown {
				width: 100vw;
				right: -10px;
				height: 100vh;
				display: flex;
				position: fixed;
				flex-direction: column;
				left: 0;
				top: 60px;
				bottom: 0;
				right: 0;
				padding: 0;
				border-radius: 0;

				&-username-wrap {
					display: flex;
					justify-content: space-between;
					padding: 2em 1em 0;

					h3,
					span {
						color: #000000;
					}

					h3 {
						font-size: 1.5em;
						font-weight: 700;
					}

					span {
						font-weight: 500;
					}
				}

				.close-profile-dropdown {
					display: flex;
					align-items: center;
					font-weight: 400;
					cursor: pointer;

					&::before {
						display: block;
						content: "";
						background: url("../images/close.svg") no-repeat center center;
						background-size: contain;
						width: 12px;
						height: 18px;
						margin-right: 0.5em;
					}
				}

				ul {
					padding: 1em;
				}

				a {
					font-size: 1em;
					padding: 1.14em 0;
					font-weight: 500;
				}

				.blue-bg {
					margin-top: auto;
					display: block;
					margin-bottom: 2em;
				}

				&-magician {
					display: block;
					max-width: 130px;
					position: absolute;
					bottom: 60px;
					left: 50%;
					transform: translateX(-50%);
					z-index: 1;
				}
			}
		}
	}

	.share-facebook {
		padding-left: 1.5em;
		padding-right: 1.5em;
	}

	@media (max-width: $breakpoint-small) {
		.site-profile-login.button {
			padding: 0;
			background-color: transparent;
			color: white;
			font-size: 0.875em;
			display: none;
			align-items: center;
		}

		.site-profile-main {
			display: none;
		}
	}

	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		height: 100vh;
		z-index: 110;
		display: none;
	}

	.overlay.is-visible {
		display: block;
	}

	.desktop &,
	.tablet & {
		.header-ctas .loader {
			width: 2.74em;
			height: 2.74em;
			margin-left: 3.05em;
		}

		.site-profile-main.button {
			padding-left: 3.088em;
			padding-right: 0.7em;

			&::after {
				display: block;
				content: "";
				background: url("../images/dropdown-blue.svg") no-repeat center center;
				background-size: contain;
				width: 15px;
				height: 25px;
				position: absolute;
				left: 20px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}

.site-header-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
.site-search.desktop {
	display: none;
}

body:not(.mobile) .site-search.desktop {
	display: block;
}
