.ads-m200 {
  margin-top: 200px;
}

.hidden {
  display: none;
}

.quiz-result-hidden {
  display: none;
}

.quiz .quiz-ad-separator {
  font-size: 11px;
//   color: #a2a2a2;
  font-weight: 500;
}

@media (max-width: 500px) {
  .question-ad-spacer {
    min-height: 70vh;
  }
  .question-ad-spacer-answer {
    // background: transparent url("../images/swipe-down.svg") no-repeat top 20px center;
  }
}
.quiz .question-image-wrapper, .question-image-wrapper-2 {
  z-index: 2;
}

.ad-wrapper-solid {
  margin: 1em 0;
}

@media (max-width: 640px) {
  .ad-wrapper-solid {
    min-height: 300px;
    text-align: center;
    background-color: #f0f0f0 !important;
    box-shadow: 30px 0 0 0 #f0f0f0, -30px 0 0 0 #f0f0f0;
    border: none;
    padding: 35px 5px;
    margin-bottom: 70px;
  }
}
#taboola-right-rail-thumbnails {
  width: 300px;
}

button.read-more-intro-snapchat {
  margin: 0 auto;
  display: block;
  background: #ff005b;
  text-align: center !important;
  color: #f1f1f1;
  cursor: pointer;
  padding: 10px 0;
  font-family: "Proxima Nova", sans-serif !important;
  font-size: 1.2em !important;
  border: 2px solid #ff005b;
  border-radius: 5px;
  width: 85%;
  margin: 6px auto !important;
  display: block;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: color, background-color;
  -webkit-transition-property: color, background-color;
  -webkit-transition-duration: 0.3s;
  -webkit-transform: perspective(1px) translateZ(0);
  letter-spacing: 0.05em;
}

.quiz-intro .intro-btn {
  text-align: center;
}

.dx-pb-snap .pb-stream-sticky-on {
  margin-bottom: 35px;
}

.ad-android-snapchat-wrapper {
  height: 250px;
}

.sc-results {
  margin-top: 1%;
}

.dx_sticky-before {
  position: absolute;
  top: 0;
  top: 15px;
  bottom: unset;
}

.dx_sticky-inside {
  position: fixed;
  bottom: unset;
  top: 90px;
}

.dx_sticky-after {
  position: absolute;
  bottom: 0;
  bottom: 15px;
  top: unset;
}

.qc-cmp2-persistent-link {
  padding: 5px !important;
  right: 11px !important;
  font-size: 12px;
  top: 75%;
  transform-origin: center right;
  transform: rotate(-90deg);
}

.stars-bottom:before {
  top: 45px;
  left: 100px;
  animation-delay: 2.5s;
}
.stars-bottom:after {
  top: 75px;
  right: 31px;
  animation-delay: 2.7s;
}
.stars-mid:before {
  top: 82px;
  left: 65px;
  animation-delay: 2.2s;
}
.stars-mid:after {
  top: 72px;
  right: 100px;
  animation-delay: 2.5s;
}
.stars-top:before {
  top: 39px;
  left: 25px;
  animation-delay: 2s;
}
.stars-top:after {
  top: 30px;
  right: 59px;
  animation-delay: 2.2s;
}
.stars-bottom, .stars-top {
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-delay: 0s;
}
.stars .score-actions {
  animation-name: moveUp;
  animation-duration: 2s;
  animation-delay: 0s;
}

.dx-quiz-score-wrapper {
  animation-name: zoom;
  animation-duration: 2s;
  animation-delay: 0s;
}
.dx-quiz-score-wrapper .score-actions {
  animation-name: moveUp;
  animation-duration: 2s;
  animation-delay: 2s;
  animation-fill-mode: both;
}

@media (max-width: 768px) {
  .dx-quiz-stripe-button {
    font-size: 20px;
  }
}

/*# sourceMappingURL=public.css.map */
