.entry {
	&-title {
		font-size: 1.125em;
		color: black;

		a {
			color: black;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&-thumbnail {
		display: block;
	}

	&-single-top  {
		.entry-thumbnail-wrapper {
			max-width: 53.5em;
			margin-left: auto;
			margin-right: auto;
		}

		.entry-thumbnail {
			padding-bottom: 52.25%;
			position: relative;
			margin-left: auto;
			margin-right: auto;
	
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				max-height: 100%;
			}
		}
	}

	.meta-author {
		color: $color-text-muted;
		font-weight: bold;
		font-size: 12px;
		text-transform: uppercase;

		&:hover {
			text-decoration: underline;
		}
	}

	@media (max-width: 480px) {
		&-title {
			font-weight: 600;
		}
	}
}

.entry-card {
	background: white;
	border-radius: 1em;

	.entry-body {
		padding: 1em 2em;
		border-bottom-right-radius: 1em;
		border-bottom-left-radius: 1em;
	}

	.entry-title {
		color: black;
	}

	.entry-thumbnail {
		border-top-left-radius: 1em;
		border-top-right-radius: 1em;
	}
}

.entry-box {
	background: white;
	min-height: 8.85em;
	box-shadow: 0 0 0 1px rgba(black, .1);

	.entry-body {
		flex-grow: 1;
		padding: 1.5em;
	}

	.entry-excerpt {
		margin-top: .75em;
	}

	.entry-thumbnail-wrapper {
		background: #f0f0f0;
	}
}

.entry-box-horizontal {
	display: flex;

	.entry-thumbnail-wrapper {
		flex: 0 0 18em;
		position: relative;
	}

	.entry-thumbnail {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		/* IE10+ CSS styles go here */
		overflow: hidden;
		
		.entry-thumbnail {
			height: auto;
		}
   }

	@media (max-width: $breakpoint-small) {
		.entry-thumbnail-wrapper {
			flex: 0 0 8em;
			padding-bottom: 52%;
		}	
	}

	@media (max-width: $breakpoint-small) {
		flex-direction: column-reverse;

		.entry-title {
			font-weight: 600;
			font-size: 1em;
		}
	}
}

.entry-trending {
	max-width: 53em;
	margin: 0 auto;
	position: relative;

	&-header {
		margin-bottom: 1em;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}

	.trending-bubble {
		background: white;
		color: black;
		padding: .75em 1em;
		border-radius: .75em;
		border-bottom-right-radius: 0;
		line-height: 1.25;

		strong {
			font-size: 1.25em;
			font-weight: 900;
		}
	}

	.trending-magician {
		.magician-image {
			transform: rotate(15deg);
			position: absolute;
			top: -3em;
			right: -6em;
		}

		.magician-hand {
			position: absolute;
			top: -.25em;
			z-index: 2;
			right: 4em;
		}

		.magician-star-1,
		.magician-star-2,
		.magician-star-3 {
			height: 2em;
			width: 2em;
			background: transparent url('../images/star.svg') no-repeat center center;
			background-size: contain;
			position: absolute;
			z-index: 2;
			right: 2em;
			top: 3em;
		}

		.magician-star-2 {
			top: -4em;
			right: -3em;
			opacity: .7;
		}

		.magician-star-3 {
			top: 8em;
			right: -8em;
			opacity: .2;
		}
	}

	.trending-type {
		font-weight: 900;
		text-transform: uppercase;
	}

	.entry-card {
		position: relative;
	}
	
	.entry-thumbnail-wrapper {
		position: relative;
		padding-bottom: 50%;
		
		.entry-thumbnail {
			z-index: 1;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.entry-body {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.button {
			min-width: 10em;
			text-align: center;
		}
	}

	.entry-title {
		margin-top: .5em;
		margin-bottom: .5em;
	}

	@media (max-width: $breakpoint-small) {
		.trending-magician {
			display: none;
		}

		.trending-bubble {
			font-size: .75em;
		}

		.entry-body {
			flex-direction: column;

			.entry-title {
				margin-bottom: 1em;
			}
		}
	}
}

.entry-simple {
	margin-bottom: 2em;

	.entry-title {
		font-size: 1.25em;
	}

	.entry-thumbnail-wrapper {
		margin-bottom: 1em;
		display: block;
		padding-bottom: 52%;
		position: relative;
		background: #f0f0f0;
		border-radius: 1em;
		overflow: hidden;
	}

	.entry-thumbnail {
		border-radius: .5em;
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.entries-list {
	.entry-box {
		margin-bottom: 1.75em;

		&:last-child {
			margin-bottom: 0;
		}
	}
}