// Global settings
$global-layout-gutter: 20px;
$global-font-size: 16px;
$global-width: 1200px;


// Standart set media queries
$breakpoint-mobile: 480px;
$breakpoint-small: 	640px;
$breakpoint-medium: 960px;
$breakpoint-large: 	1200px;


// Layout
$layout-header-height: 70px;


// Colors:
$color-green: #5ea54d;
$color-blue: #4072db;
$color-navy: #3f3f75;
$color-orange: #ff4902;

$color-primary: #006EE0;
$color-primary-dark: #0051A6;
$color-secondary: #ff3300;

$color-text-muted: #666;

// Fonts
$font-serif: "Palatino Linotype", "Book Antiqua", Palatino, Georgia, "Times New Roman", serif;
$font-sans: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$font-heading: $font-sans;
