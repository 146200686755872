.page-template-user-profile,
.page-template-user-results {
	.section-welcome {
		overflow: hidden;
	}

	.section-quiz-main {
		padding-top: 0;
	}

	.entries-list {
		margin-top: -2em;
	}

	.load-more-area {
		background-color: white;
		padding: 2em;
		display: flex;
		justify-content: center;
		border-radius: 1em;
	}

	.load-more-results {
		cursor: pointer;
	}

	.quiz-main .results-container {
		// To reduce jumping for the first load.
		min-height: 78em;
	}

	.user-profile-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 5em 0;
	}

	.edit-profile-heading {
		font-size: 1.75em;
		padding-bottom: 1.6em;
		color: #000000;
		font-weight: 400;
	}

	.edit-profile-column-heading {
		font-size: 1.25em;
		font-weight: 700;
	}

	@media (min-width: $breakpoint-small) {
		.align-right {
			display: flex;
			justify-content: flex-end;
		}
	}

	.margin-bottom-5 {
		margin-bottom: 5em;

		@media (max-width: $breakpoint-small) {
			margin-bottom: 2em;
		}
	}

	.has-border-bottom {
		border-bottom: 1px solid #dddddd;
	}

	@media (max-width: $breakpoint-small) {
		.section-quiz-main {
			padding-top: 2em;
		}
	}
}
