// sass-lint:disable no-duplicate-properties

// ==========================================================================
// Basic stylings
// ==========================================================================

body,
button,
input,
select,
textarea {
	color: #000;
	font-family: sans-serif;
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.5;
	letter-spacing: 0.2px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	clear: both;
}

p {
	margin-bottom: 1.5em;
}

dfn,
cite,
em,
i {
	font-style: italic;
}

blockquote {
	margin: 0 1.5em;
}

address {
	margin: 0 0 1.5em;
}

pre {
	background: #eee;
	font-family: "Courier 10 Pitch", Courier, monospace;
	font-size: 15px;
	font-size: 0.9375rem;
	line-height: 1.6;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

code,
kbd,
tt,
var {
	font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
	font-size: 15px;
	font-size: 0.9375rem;
}

abbr,
acronym {
	border-bottom: 1px dotted #666;
	cursor: help;
}

mark,
ins {
	background: #fff9c0;
	text-decoration: none;
}

big {
	font-size: 125%;
}

dt {
	font-weight: bold;
}

dd {
	margin: 0 1.5em 1.5em;
}

table {
	margin: 0 0 1.5em;
	width: 100%;
}
