.ad-container {
	text-align: center;

	// We always force at least 20px top/bottom
	// Top is less due to the label
	margin-top: 0.5em;
	margin-bottom: 1.25em;

	&-label {
		margin-bottom: 0.25em;

		span {
			text-transform: uppercase;
			font-weight: 500;
			font-size: 11px;
			color: #555;
		}
	}
}

.quiz-ad-separator {
	color: #555;
}

// Incoming from the plugin
.single-quiz {
	.dx-rtk-wrapper-solid {
		margin: 20px 0;
	}

	.ad-wrapper-solid {
		min-height: 300px;
		text-align: center;
		display: none;

		&.is-active {
			display: block;
		}

		.ad-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 250px;
		}
	}

	.sticky-ad {
		min-height: 250px;
	}

	.dx_sticky-before {
		position: relative;
		top: 0;
	}

	.sticky-side-ad {
		display: none;

		&.is-active {
			display: block;
		}
	}

	.dx-sticky-ad {
		display: block;
		bottom: 0;
		left: 0;
		right: 0;
	}

	// When you scroll down, it sticks to the top.
	.dx_sticky-inside {
		width: 300px;
	}

	// When you reach the end, it stays at the bottom.
	.dx_sticky-after {
		width: 300px;
		left: 0;
	}

	&.mobile .quiz {
		.ad-wrapper-solid {
			align-items: center;
			flex-direction: column;
			display: none;

			&.is-active {
				display: flex;
			}
		}

		.ad-wrapper {
			height: 250px;
			width: 300px;
			overflow: hidden;
			min-height: 0;
			contain: strict;
		}
	}
}

.quiz {
	.mobile & .ad-wrapper {
		height: 250px;
		min-height: 260px;
	}
	
	.mobile & .outer-ad-container {
		height: 250px;
		width: 300px;
		overflow: hidden;
		min-height: 0;
		contain: strict;
		
		display: flex;
		align-items: center;
		justify-content: center;
		
		.ad-wrapper {
			display: block;	
			min-width: 300px; 
			contain: none;
			width: 100%;
			height: 100%;
		}
	}
}

.site-header {
	.sticky-ad {
		// background: #f0f0f0;
		justify-content: center;
		top: 100%;
		left: 0;
		width: 100%;
		position: absolute;
		min-height: 50px;
		max-height: 50px;
		display: none;

		&.is-active {
			display: flex;
			background: white;
			padding-bottom: 55px;
		}
	}

	.has-sticky {
		display: flex;
	}
}

.footer-sticky {
	position: fixed;

	// Same value as the header height
	top: 0;
	left: 0;
	width: 100%;

	min-height: 50px;
	display: none;
	align-items: center;
	justify-content: center;
	z-index: 90;

	// Adding this so that the area around the ad is NOT clickable.
	// This is needed as there are ads that would otherwise go behind the wrapper.
	pointer-events: none;

	& > * {
		// All children is a safe selector. In reality most often this is just one DIV.
		pointer-events: all;
	}

	&.is-active {
		display: flex;
	}

	.desktop & {
		top: 79px;
	}
	
	@media (min-width: 1320px) and (max-width: 1480px) {
			padding-right: 0;
	}
}



.outer-ad-container {
	//padding: 15px 20px;
	
	&.sticky-unit {
		//padding: 0 10px;
		//height: 50px;
	}
}


.desktop .ad-container.mobile {
	display: none;
}


.mobile .ad-container.desktop {
	display: none;
}