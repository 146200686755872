// We might move out some styles to default widgets in the future.
.widget-popular-posts {
	background: white;
	position: relative;
	box-shadow: 0 0 0 1px rgba(black, .1);

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: -1px;
		left: -1px;
		height: calc(100% + 2px);
		width: .5em;
		background: linear-gradient(180deg, #E33D3D 0.01%, #FFC700 100%);
		z-index: 1;
	}
}

.widget-area {
	.widget {
		margin-bottom: 2em;
	}
	
	.widget-title {
		font-size: 1em;
		margin-bottom: 1em;
	}

	ul {
		padding: 0;
		margin: 0;
	}

	a {
		color: black;
	}

	li {
		border-bottom: 1px solid #ddd;
		padding: 1em 0;
	}
}