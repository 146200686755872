.section-404 {
	min-height: 100vh;

	background-color: $color-primary-dark;
	background-image: url('../images/bg-rays.svg'), radial-gradient(darken($color-primary, 2%), $color-primary 50%);
	background-repeat: no-repeat, no-repeat;
	background-size: cover, cover;
	background-position: center center;

	display: flex;
	align-items: center;

	.row {
		width: 100%;
	}

	.error-404-main {
		display: flex;
		justify-content: center;
		flex-direction: column;
		text-align: center;
	}

	img {
		display: block;
		margin: 0 auto 2em;
	}

	h1 {
		color: white;
		margin-bottom: 1em;
		font-weight: 300;
	}

	.search-submit {
		display: none;
	}

	.error-404-goback {
		display: block;
		margin-top: 7em;
		color: white;
		font-weight: bold;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		
		svg {
			margin-right: 1em;
		}
	}

	.search-form {
		label {
			max-width: 28em;
			margin: 0 auto;
			position: relative;
			height: 100%;
			display: block;
			font-size: 1.125em;

			&:after {
				content: '';
				display: block;
				background: lighten($color-primary, 5%) url('../images/search.svg') no-repeat center center;
				top: 0;
				left: 0;
				height: 100%;
				width: 5em;
				position: absolute;
				border-bottom-left-radius: 5em;
				border-top-left-radius: 5em;
			}
		}
	}

	.search-field {
		padding: 1em 1.5em 1em 6em;
		border: none;
		box-sizing: border-box;
		background-color: white;
		font-weight: 700;
		color: black;
		width: 100%;
		box-shadow: 0 10px 30px -15px rgba(black, .2);

		&::placeholder {
			color: black
		}
	}

	@media (max-width: 400px) {
		.search-form label {
			font-size: 1em;
		}
		
		.search-form label:after {
			display: none;
		}
		
		.search-field {
			font-weight: 500;
			padding-left: 1.5em;;
		}
	}
}

.no-results {
	.page-header {
		margin-bottom: 1em;
	}
}