.section {
	// Default modifiers
	&-border-bottom {
		border-bottom: 1px solid #ddd;
	}

	&-border-top {
		border-top: 1px solid #ddd;
	}
}

/**
 * The standard section wrapper. Use this with other classes for the base styling.
 * Default paddings are 40 and 80px
 */
.section-fullwidth {
	$border-color: #ddd;
	$section-padding: 40px;

	padding: #{$section-padding} 0;
	position: relative;

	@media (min-width: $breakpoint-small) {
		padding: #{$section-padding * 2} 0;
	}

	&.shadow {
		box-shadow: 0 5px 10px -5px rgba(black, 0.3);
		z-index: 10;
	}

	&.shadow-inset {
		box-shadow: inset 0 5px 10px -5px rgba(black, 0.3);
		z-index: 10;
	}

	&.inverted {
		background: black;
		color: white;

		hr {
			background-color: rgba(white, 0.2);
		}
	}

	&.text-white {
		color: white;

		hr {
			background-color: rgba(white, 0.2);
		}

		a {
			color: white;
		}
	}
}

.section-background {
	&-light {
		background: #fafafa;
	}
}

.section-center-row {
	display: flex;
	align-items: center;

	.row {
		width: 100%;
	}
}




.section-welcome {
	@extend .section-fullwidth.text-white;
	position: relative;

	// This is the top spacing we need for the first section in order
	// to add enough space for the header.
	padding-top: 7em; // Value when the sticky is not on top

	.single & {
		// Value with sticky on top
		padding-top: 13em;
	}

	min-height: 400px;
	background-color: $color-primary-dark;
	background-image: url("../images/bg-rays.svg"), radial-gradient(darken($color-primary, 4%), $color-primary 70%);
	background-repeat: no-repeat, no-repeat;
	background-size: cover, cover;
	background-position: center center;

	.has-sticky & {
		padding-top: 10em;
	}

	.page-template-homepage & {
		overflow: hidden;
	}

	.archive & {
		min-height: 200px;
		display: flex;
		align-items: center;

		.row {
			width: 100%;
		}
	}

	.row {
		position: relative;
		z-index: 1;
	}

	&:after {
		content: "";
		display: block;
		background: url("../images/bg-diamonds-bundle.png");
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	@media (max-height: 728px) {
		padding-top: 5em;

		.entry-trending .entry-thumbnail-wrapper {
			padding-bottom: 45%;
		}
	}

	@media (max-width: $breakpoint-small) {
		padding-top: 7em;
		min-height: 0;
		// background: none;
	}
}

.section-tags-overlap {
	// Some hardcoding is needed to make this work nicely. Keep in mind that
	// if the text goes on two lines we might need JS or force visibility changes.
	margin-top: -2em;
	margin-bottom: -2em;
	height: 4em;

	position: relative;
	z-index: 1;

	h3 {
		display: none;
	}

	@media (max-width: $breakpoint-medium) {
		margin: 0;
		height: auto;

		.box-tags {
			h3 {
				display: block;
				margin-bottom: 0.5em;
				padding-bottom: 0.5em;
				text-align: center;
			}

			ul {
				flex-wrap: wrap;
			}

			li {
				flex: 1 1 auto;
				margin: 0.5em;
			}
		}
	}

	@media (max-width: $breakpoint-small) {
		.box-tags {
			ul {
				display: block;
				text-align: center;
			}

			li {
				display: inline-block;
				margin: 0.25em;
			}

			a {
				display: inline-block;
				padding: 0.25em 0.5em;
				border: 1px solid #ddd;
				border-radius: 5em;
			}
		}
	}
}

.section-home-intro {
	position: relative;

	&:after {
		content: "";
		display: block;
		height: 10px;
		width: 100%;
		position: absolute;
		bottom: 0;
		background: url("../images/colors.svg");
	}

	@media (max-width: $breakpoint-medium) {
		.row {
			max-width: 40em;
		}

		.large-4 {
			margin-top: 2em;
		}
	}
}

.section-other-sites {
	position: relative;

	&:after {
		content: "";
		display: block;
		height: 10px;
		width: 100%;
		position: absolute;
		top: 0;
		background: url("../images/colors.svg");
	}

	.section-heading {
		text-align: center;

		.heading-title {
			font-weight: 300;
			font-size: 2.5em;
			margin-bottom: 0.25em;
		}

		.heading-subtitle {
			font-weight: 300;
			font-size: 1.75em;
		}
	}
}

.section-posts-grid {
	@media (max-width: 420px) {
		.column.small-6 {
			flex: 0 0 100%;
			max-width: 100%;
			animation: slide-up 0.6s ease;

			&:last-child .entry {
				margin-bottom: 0;
			}
		}
	}
}

.section-footer-nav {
	background: #f0f0f0;

	ul {
		text-align: center;
	}

	li {
		margin: 1em;
		display: inline-block;
	}

	a {
		color: black;
	}

	@media (max-width: $breakpoint-small) {
		li {
			margin: 0.5em 1em;
		}
	}
}

.section-footer-message {
	text-align: center;
	background: radial-gradient(#333, #222);
	padding: 2em 0;
	position: relative;

	&:before {
		content: "";
		display: block;
		height: 10px;
		width: 100%;
		position: absolute;
		bottom: 0;
		background: url("../images/colors.svg");
		z-index: 1;
	}

	img {
		max-width: 100px;
	}

	p {
		font-size: 1em;
		font-weight: 500;
		color: white;
		margin: 0;
	}
}

.section-loader {
	position: relative;

	&::before {
		display: block;
		content: '';
		position: absolute;
		background-size: 100%;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: #fcfcfc;
		z-index: 2;
	}

	.login-loaded & {
		&::before {
			display: none;
			background: transparent;
		}
	}
}

@keyframes slide-up {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
