.site-login-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba($color-primary, 0);
	z-index: 9000;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	transition: 1s;

	.login-error {
		display: none;
		max-width: 34em;
		font-weight: bold;
		color: red;
		padding: 1em;

		&.is-visible {
			display: block;
		}
	}

	.popup-card {
		&-main {
			padding-bottom: 1.5em;
			text-align: center;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		&-footer {
			text-align: center;
			padding-top: 1.5em;
			padding-bottom: 1.5em;
		}

		.button-social-login {
			margin: 0 auto 0.5em;
		}
	}

	&.is-visible {
		background: rgba($color-primary, 0.87);
		pointer-events: all;
		transition: 0.5s;

		.popup-card {
			transform: scale(1);
			opacity: 1;
			transition: 0.5s;
			transition-delay: 0.25s;
			margin: auto 0;

			@media (max-width: 320px) {
				min-width: 320px;
				transform: scale(0.8);
			}
		}

		.popup-card-footer {
			transition: 0.5s;
			transition-delay: 0.5s;
			transform: rotateX(0) translateY(0px);
			transition-timing-function: ease-in-out;
			border-top-left-radius: 0;
			border-top-right-radius: 0;

			h2 {
				max-width: 12em;
				margin: 0 auto;

				@media (max-width: 320px) {
					font-size: 1.5em;
				}
			}
		}

		.popup-magician-behind {
			transition: 0.5s;
			transition-delay: 1s;
			transform: translate(-50%, -50%);
		}

		.privacy-policy {
			opacity: 1;
		}
	}

	.determining-results-login &.is-visible {
		background: rgba($color-primary, 1);
		transition: 0s;
	}

	@media (max-width: 42.5em) {
		padding: 1em;
	}

	@media (max-width: 420px) {
		overflow-y: scroll;
	}
}

.popup-card {
	position: relative;

	transform: scale(0.5);
	transition: 1s;
	opacity: 0;
	perspective: 50px;
	perspective-origin: top;

	h2 {
		font-size: 1.75em;
	}

	p {
		margin-top: 0.25em;
	}

	&-inner {
		padding: 3em;
		background: white;
		border-radius: 1em;
	}

	&-footer {
		border-top: 1px solid #ddd;

		transform-style: preserve-3d;
		transform: rotateX(-9.2deg);
		transform-origin: top;
		transition: 1s;
	}

	.popup-magician-behind {
		position: absolute;
		z-index: -1;
		left: 50%;
		transform: translate(-50%, 0%);
		transition-duration: 1s;
	}

	@media (max-width: 42.5em) {
		&-inner {
			padding: 1.5em;
		}

		.list-icons {
			flex-direction: column;

			.list-item {
				margin-right: 0;
			}
		}
	}
}

.enter-email-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba($color-primary, 0);
	z-index: 9000;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	transition: 1s;

	.login-error {
		display: none;
		max-width: 34em;
		font-weight: bold;
		color: red;
		padding: 1em;

		&.is-visible {
			display: block;
		}
	}

	.popup-card {
		&-main {
			text-align: center;
		}

		&-footer {
			text-align: center;
		}

		.button-social-login {
			margin: 0 auto 0.5em;
		}
	}

	&.is-visible {
		background: rgba($color-primary, 0.87);
		pointer-events: all;
		transition: 0.5s;

		.popup-card {
			transform: scale(1);
			opacity: 1;
			transition: 0.5s;
			transition-delay: 0.25s;
		}

		.popup-card-footer {
			transition: 0.5s;
			transition-delay: 0.5s;
			transform: rotateX(0) translateY(5px);
			transition-timing-function: ease-in-out;
		}

		.popup-magician-behind {
			transition: 0.5s;
			transition-delay: 1s;
			transform: translate(-50%, -50%);
		}
	}

	@media (max-width: 42.5em) {
		padding: 1em;
	}
}

.popup-card {
	position: relative;

	transform: scale(0.5);
	transition: 1s;
	opacity: 0;
	perspective: 50px;
	perspective-origin: top;

	h2 {
		font-size: 1.75em;
	}

	p {
		margin-top: 0.25em;
	}

	&-inner {
		padding: 3em;
		background: white;
		border-radius: 1em;
	}

	&-footer {
		border-top: 1px solid #ddd;

		transform-style: preserve-3d;
		transform: rotateX(-9.2deg);
		transform-origin: top;
		transition: 1s;
	}

	&-close {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 2em;
		height: 2em;
		border: none;
		background: #e3edf8;
		border-radius: 0.25em;
		position: absolute;
		top: 1em;
		right: 1em;
		cursor: pointer;

		&::before {
			display: block;
			content: "";
			background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMSAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik02LjUzMTI1IDZMOS44NzUgOS4zNzVDMTAuMTI1IDkuNjA0MTcgMTAuMTI1IDkuODMzMzMgOS44NzUgMTAuMDYyNUw5LjA5Mzc1IDEwLjg0MzhDOC44NjQ1OCAxMS4wOTM4IDguNjM1NDIgMTEuMDkzOCA4LjQwNjI1IDEwLjg0MzhMNS4wMzEyNSA3LjVMMS42NTYyNSAxMC44NDM4QzEuNDI3MDggMTEuMDkzOCAxLjE5NzkyIDExLjA5MzggMC45Njg3NSAxMC44NDM4TDAuMTg3NSAxMC4wNjI1Qy0wLjA2MjUgOS44MzMzMyAtMC4wNjI1IDkuNjA0MTcgMC4xODc1IDkuMzc1TDMuNTMxMjUgNkwwLjE4NzUgMi42MjVDLTAuMDYyNSAyLjM5NTgzIC0wLjA2MjUgMi4xNjY2NyAwLjE4NzUgMS45Mzc1TDAuOTY4NzUgMS4xNTYyNUMxLjE5NzkyIDAuOTA2MjUgMS40MjcwOCAwLjkwNjI1IDEuNjU2MjUgMS4xNTYyNUw1LjAzMTI1IDQuNUw4LjQwNjI1IDEuMTU2MjVDOC42MzU0MiAwLjkwNjI1IDguODY0NTggMC45MDYyNSA5LjA5Mzc1IDEuMTU2MjVMOS44NzUgMS45Mzc1QzEwLjEyNSAyLjE2NjY3IDEwLjEyNSAyLjM5NTgzIDkuODc1IDIuNjI1TDYuNTMxMjUgNloiIGZpbGw9IiMwMTYyQzciLz4KPC9zdmc+")
 				no-repeat center center;
			background-size: contain;
			width: 15px;
			height: 15px;
		}

		svg {
			display: block;
		}

		svg path {
			fill: $color-primary;
		}
	}

	.popup-magician-behind {
		position: absolute;
		z-index: -1;
		left: 50%;
		transform: translate(-50%, 0%);
		transition-duration: 1s;
	}

	@media (max-width: 42.5em) {
		&-inner {
			padding: 1.5em;
		}

		.list-icons {
			flex-direction: column;

			.list-item {
				margin-right: 0;
			}
		}
	}

	@media (max-width: 400px) {
		.popup-magician-behind {
			display: none;
		}
	}

	@media (max-width: 400px) {
		.list-icons {
			.list-item {
				text-align: left;
			}
		}

		.popup-card-footer {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		& > .privacy-policy {
			background: white;
			border-bottom-left-radius: 1em;
			border-bottom-right-radius: 1em;
			color: black;
			max-width: 100%;
			margin-top: 0;
			padding: 1.5em 0;
			border-top: 1px solid #ddd;
			opacity: 0;
			transition: 0.5s ease-in-out 1s;

			a {
				color: #0162c7;
			}
		}
	}
}

.color-blue {
	color: #0162c7;
}
