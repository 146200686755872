.author {
    @media (max-width: $breakpoint-small) {
        .section-welcome {
            padding-top: 4em;

            .heading-title {
                font-size: 2.5em;
            }
        }

        .author-load-more {
            margin-top: 2em;
        }
    }
}

.category {
    @media (max-width: $breakpoint-small) {
        .section-welcome {
            padding-top: 4em;

            .heading-title {
                font-size: 2.5em;
            }
        }

        .category-load-more {
            margin-top: 2em;
        }
    }
}